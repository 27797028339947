import { FC } from 'react'
import { Container, Text } from './styled'

type Props = {
  backgroundColor?: string
  children: string
  textColor?: string
}

export const Quote: FC<Props> = ({ backgroundColor, children, textColor }) => (
  <Container backgroundColor={backgroundColor}>
    <Text color={textColor}>{children}</Text>
  </Container>
)
