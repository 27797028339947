import { FC } from 'react'
import { Text, Title } from '../UI/Typography'
import { Container, Description, Image } from './styled'

type Props = {
  imageUrl?: string
  text?: string
  title?: string
}

export const Article: FC<Props> = ({ imageUrl, text, title }) => (
  <Container>
    {imageUrl && <Image data-testid="image" src={imageUrl} />}
    <Description>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Description>
  </Container>
)
