import { ITabItem } from 'ducks/pages'
import { FC, useEffect, useState } from 'react'
import { Text, Title } from '../UI/Typography'
import {
  Container,
  Content,
  ContentDescription,
  ContentImage,
  ContentLinkExternal,
  ContentLinkInternal,
  ContentLinks,
  ContentSubtitle,
  ContentText,
  ContentTitle,
  Navigation,
  NavigationButton,
} from './styled'

type Props = {
  title?: string
  subtitle?: string
  items?: ITabItem[]
}

type LinkProps = {
  children?: string
  external?: boolean
  href?: string
}

const Link: FC<LinkProps> = ({ children = 'Get started', external, href = '/' }) => {
  return external ? (
    <ContentLinkExternal href={href} target="blank">
      {children}
    </ContentLinkExternal>
  ) : (
    <ContentLinkInternal to={href}>{children}</ContentLinkInternal>
  )
}

export const Tabs: FC<Props> = (props) => {
  const { items, subtitle, title } = props
  const [selectedTab, setSelectedTab] = useState<ITabItem | null>(null)
  useEffect(() => {
    if (items && items.length > 0) {
      setSelectedTab(items[0])
    }
  }, [items])

  const renderNavigationButton = (item: ITabItem) => {
    const onClick = () => {
      setSelectedTab(item)
    }
    return (
      <NavigationButton key={item.id} active={selectedTab?.id === item.id} onClick={onClick}>
        {item.title}
      </NavigationButton>
    )
  }
  const renderTextTab = (): { __html: string } => {
    return { __html: selectedTab?.text || 'texto' }
  }
  return (
    <Container>
      <Title>{title}</Title>
      <Text>{subtitle}</Text>
      <Navigation data-testid="tabNavigator">{items?.map(renderNavigationButton)}</Navigation>
      <Content>
        {selectedTab?.image && <ContentImage src={selectedTab?.image?.url} />}
        <ContentDescription>
          <ContentTitle>{selectedTab?.title}</ContentTitle>
          <ContentSubtitle>{selectedTab?.subtitle}</ContentSubtitle>
          <ContentText id="htmlRender" dangerouslySetInnerHTML={renderTextTab()} />
          <ContentLinks>
            {selectedTab?.main_link && (
              <Link external={selectedTab?.main_link_external} href={selectedTab?.main_link}>
                {selectedTab?.main_link_label}
              </Link>
            )}
            {selectedTab?.link && (
              <Link external={selectedTab?.link_external} href={selectedTab?.link}>
                {selectedTab?.main_link_label}
              </Link>
            )}
          </ContentLinks>
        </ContentDescription>
      </Content>
    </Container>
  )
}
