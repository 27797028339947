import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

export const Container = styled.main`
  min-height: 100vh;
  padding-top: 72px;
`

export const Content = styled.div`
  padding-top: 25px;
`

export const Banner = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  align-items: flex-end;
  padding: 50px 15%;
  width: 100%;
  height: 540px;
  @media screen and (max-width: 1024px) {
    padding: 50px 5%;
  }
  @media screen and (max-width: 450px) {
    height: 220px;
    padding: 25px 5%;
  }
`

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 800;
  font-size: 64px;
  line-height: 64px;
  color: ${({ theme }) => theme.colors.white};
  width: 60%;
  @media screen and (max-width: 1024px) {
    font-size: 48px;
    line-height: 48px;
  }
  @media screen and (max-width: 450px) {
    font-size: 28px;
    line-height: 28px;
    width: 100%;
  }
`
