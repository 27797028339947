import { FC } from 'react'
import { Text, Title } from '../UI/Typography'
import { Container, Content, ContentImage } from './styled'

type Props = {
  backgroundColor?: string
  imageUrl?: string
  message?: string
  title?: string
}

export const Banner: FC<Props> = ({ backgroundColor, imageUrl, message, title }) => (
  <Container backgroundColor={backgroundColor}>
    <Content>
      <Title>{title}</Title>
      <Text>{message}</Text>
    </Content>
    {imageUrl && <ContentImage data-testid="image" background={`url(${imageUrl})`} />}
  </Container>
)
