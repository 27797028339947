/* eslint-disable react/jsx-props-no-spreading */
import * as yup from 'yup'
import { contactPageSelector, LOAD_CONTACT } from 'ducks/pages'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Label, PageTitle, Text } from 'views/components/UI/Typography'
import { Input } from 'views/components/Input'
import { TextArea } from 'views/components/TextArea'
import { contactSelector, ContactValues, RESET, SUBMIT } from 'ducks/contact'

import {
  Container,
  Content,
  Description,
  Form,
  FormButton,
  FormButtonFeedback,
  FormButtonLoadingSVG,
  FormError,
  FormTitle,
} from './styled'

const validationSchema: yup.SchemaOf<ContactValues> = yup.object({
  contact_name: yup.string().required('Name is required'),
  contact_email: yup.string().email('Email must be valid').required('Name is required'),
  company: yup.string().required('Company is required'),
  role: yup.string().required('Role is required'),
  message: yup.string().required('Message is required'),
})

const Contact: FC = () => {
  const dispatch = useDispatch()
  const contact = useSelector(contactPageSelector)
  const contactState = useSelector(contactSelector)
  const error = contactState.get('error')
  const loading = contactState.get('loading')
  const success = contactState.get('success')
  const { email, form, message, phone, subtitle, title } = contact || {}
  const {
    formState: { errors },
    handleSubmit,
    getValues,
    register,
    reset,
  } = useForm<ContactValues>({
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    dispatch({ type: LOAD_CONTACT })
  }, [dispatch])

  const onSubmit = (values: ContactValues) => {
    // eslint-disable-next-line no-console
    dispatch(SUBMIT.trigger(values))
  }

  const onSendAnother = () => {
    reset()
    dispatch({ type: RESET })
  }

  const renderButton = () => {
    if (success) {
      return (
        <FormButtonFeedback success onClick={onSendAnother}>
          Message Sent! Click here to send another one.
        </FormButtonFeedback>
      )
    }
    if (loading) {
      return (
        <FormButtonFeedback>
          <FormButtonLoadingSVG />
        </FormButtonFeedback>
      )
    }
    return <FormButton type="submit">{form?.button}</FormButton>
  }

  return (
    <Container>
      <Content>
        <PageTitle>{title}</PageTitle>
        <Text>{subtitle}</Text>
        <Description>
          <Label>{message}</Label>
          <Label>{phone}</Label>
          <Label>{email}</Label>
        </Description>
      </Content>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{form?.title}</FormTitle>
        <Input
          placeholder="Name"
          {...register('contact_name')}
          getValues={getValues}
          error={errors.contact_name?.message}
        />
        <Input
          placeholder="Email Address"
          {...register('contact_email')}
          getValues={getValues}
          error={errors.contact_email?.message}
        />
        <Input placeholder="Company" {...register('company')} getValues={getValues} error={errors.company?.message} />
        <Input placeholder="Role" {...register('role')} getValues={getValues} error={errors.role?.message} />
        <TextArea
          placeholder="Your message"
          {...register('message')}
          getValues={getValues}
          error={errors.message?.message}
        />
        <FormError>{error}</FormError>
        {renderButton()}
      </Form>
    </Container>
  )
}

export default Contact
