import { ChangeEvent, FC, Ref, forwardRef, useState } from 'react'
import { Container, Error, InputContainer, StyledTextArea, StyledLabel } from './styled'

type Props = {
  error?: string
  getValues: (field: string) => string
  placeholder?: string
  onChange?: (event: ChangeEvent) => void
  ref?: Ref<HTMLTextAreaElement>
  name: string
}

export const TextArea: FC<Props> = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { error, getValues, placeholder, onChange, name } = props
  const [focused, setFocused] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)

  const handleBlur = () => {
    setFocused(false)
    setIsEmpty(!getValues(name))
  }

  const handleFocus = () => {
    setFocused(true)
  }

  return (
    <Container>
      <InputContainer error={error} focus={focused}>
        <StyledTextArea
          error={error}
          onBlur={handleBlur}
          onChange={onChange}
          name={name}
          onFocus={handleFocus}
          ref={ref}
          rows={5}
          placeholder={placeholder}
        />
        <StyledLabel empty={isEmpty} error={error} focus={focused} htmlFor={name}>
          {placeholder}
        </StyledLabel>
      </InputContainer>
      {error && <Error>{error}</Error>}
    </Container>
  )
})
