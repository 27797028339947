import { StaticContentResponse } from '@types'
import axios from 'axios'

export const getStaticContent = async (): Promise<StaticContentResponse> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          footer {
            logo {
              url
            }
            options {
              external
              label
              path
            }
            socials {
              url
              name
              icon {
                url
              }
            }
            terms {
              external
              label
              path
            }
          }
          header {
            logo {
              url
            }
            page_opengraph{
              title
              description
              image{
                url
              }
            }
            options {
              external
              label
              path
            }
          }
        }
      `,
    })
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
