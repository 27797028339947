/* eslint-disable @typescript-eslint/no-explicit-any */
import createImmutableSelector from 'lib/immutable/selector'
import { Selector } from 'reselect'
import { IPersist } from '../models'

// TODO: pass Store type
const getPersist = (state: any) => state.get('persist')

export const getRehydrateSelector: Selector<any, IPersist> = createImmutableSelector(getPersist, (persist) =>
  persist.get('rehidrated')
)

export default getRehydrateSelector
