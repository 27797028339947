import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Container = styled.section`
  width: 100%;
`

type ContentProps = {
  backgroundUrl?: string
}

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 784px;
  background: url(${({ backgroundUrl }) => backgroundUrl});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding: 50px 15%;
  @media screen and (max-width: 1024px) {
    padding: 50px 5%;
  }
  @media screen and (max-width: 1024px) {
    height: 500px;
  }
`

const link = css`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const LinkButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LinkButtonIcon = styled.img`
  width: 14px;
  margin-left: 3.5px;
  height: 18px;
`

export const LinkExternal = styled.a`
  ${link}
`

export const LinkInternal = styled(Link)`
  ${link}
`

export const LinkLabel = styled.p`
  font-family: Inter;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
`

export const LinkSeparator = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.black};
  width: 80px;
  height: 0;
  margin: 0 8px;
`

type NavigationButtonProps = {
  navigationType?: 'next' | 'previous'
}

export const NavigationButton = styled.button<NavigationButtonProps>`
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  left: ${({ navigationType }) => navigationType === 'previous' && '5%'};
  right: ${({ navigationType }) => navigationType === 'next' && '5%'};
  &::before {
    content: '';
  }
`

export const NavigationButtonIcon = styled.img`
  width: 18px;
  height: 32px;
`
