import styled from 'styled-components'

type TypographyProps = {
  color?: string
  textAlign?: 'center' | 'end' | 'start'
  width?: string
}

export const Label = styled.p<TypographyProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ color, theme }) => color || theme.colors.black};
  text-align: ${({ textAlign }) => textAlign || 'start'};
  width: ${({ width }) => width || '100%'};
  font-size: 16px;
  line-height: 22px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`

export const PageTitle = styled.h1<TypographyProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ color, theme }) => color || theme.colors.black};
  text-align: ${({ textAlign }) => textAlign || 'start'};
  margin: 0;
  font-weight: 800;
  font-size: 72px;
  line-height: 98px;
  @media screen and (max-width: 768px) {
    font-size: 48px;
    line-height: 64px;
  }
`

export const Text = styled.p<TypographyProps>`
  margin: 10px 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  line-height: 32px;
  color: ${({ color, theme }) => color || theme.colors.black};
  text-align: ${({ textAlign }) => textAlign || 'start'};
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const Title = styled.h2<TypographyProps>`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 50px;
  color: ${({ color, theme }) => color || theme.colors.black};
  text-align: ${({ textAlign }) => textAlign || 'start'};
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 46px;
  }
`
