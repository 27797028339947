import { ILink } from '@types'
import useMediaQuery from 'hooks/useMediaQuery'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import Menu from './Menu'
import { Container, ExternalLink, InternalLink, Logo, Options } from './styled'

type Props = {
  logo?: string
  options?: ILink[]
}

export const Header: FC<Props> = ({ logo, options }) => {
  const matches = useMediaQuery('(min-width: 768px)')

  const renderOption = (option: ILink) => {
    return option?.external ? (
      <ExternalLink key={option.path} href={option.path} target="blank">
        {option.label}
      </ExternalLink>
    ) : (
      <InternalLink key={option.path} to={option.path}>
        {option.label}
      </InternalLink>
    )
  }

  return (
    <Container>
      {logo && (
        <Link to="/">
          <Logo src={logo} />
        </Link>
      )}
      {matches ? <Options data-testid="options">{options?.map(renderOption)}</Options> : <Menu options={options} />}
    </Container>
  )
}
