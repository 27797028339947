import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Grid = styled.div`
  margin: 5% 0 2.5%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px 30px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    grid-gap: 30px 30px;
  }
`

export const Header = styled.div`
  max-width: 540px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ItemIcon = styled.img`
  width: 30px;
  height: 30px;
`

export const ItemText = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`

export const ItemTitle = styled.h3`
  margin: 16px 0 8px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`
