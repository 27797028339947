import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const VideoContainer = styled.div`
  align-self: center;
  margin-top: 20px;
  width: 100%;
`

export const VideoWrapper = styled.div`
  width: 100% !important;
  height: 500px !important;
  @media screen and (min-width: 1600px) {
    height: 600px !important;
  }
  @media screen and (max-width: 768px) {
    height: 400px !important;
  }
  @media screen and (max-width: 450px) {
    height: 300px !important;
  }
`
