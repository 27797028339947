import { contentPageSelector, LOAD_CONTENT_PAGE } from 'ducks/pages'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Article } from 'views/components/Article'
import HtmlRender from 'views/components/HtmlRender'
import { Quote } from 'views/components/Quote'
import { Section, TextBox } from 'views/components/UI/Contents'
import { Video } from 'views/components/Video'
import { Banner, Container, Content, Title } from './styled'

type Params = {
  slug: string
}

const ContentPage: FC = () => {
  const { slug } = useParams<Params>()
  const contentPage = useSelector(contentPageSelector)
  const { article, banner, content, quote, title, videoUrl } = contentPage || {}
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: LOAD_CONTENT_PAGE, payload: slug })
  }, [dispatch, slug])

  return (
    <Container>
      <Banner background={`url(${banner?.url})`}>
        <Title>{title}</Title>
      </Banner>
      <Section noVerticalMargin>
        {content && (
          <Content>
            <TextBox>
              <HtmlRender>{content}</HtmlRender>
            </TextBox>
          </Content>
        )}
      </Section>
      <Section>
        <Article title={article?.title} text={article?.text} imageUrl={article?.image.url} />
      </Section>
      {quote?.text && (
        <Quote backgroundColor={quote?.color} textColor={quote?.textColor}>
          {quote?.text}
        </Quote>
      )}
      {videoUrl && (
        <Section>
          <Video videoUrl={videoUrl} />
        </Section>
      )}
    </Container>
  )
}

export default ContentPage
