import styled from 'styled-components'
import { Title } from '../UI/Typography'

type ContainerProps = {
  backgroundColor?: string
}

export const Container = styled.div<ContainerProps>`
  padding: 90px 15%;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.offWhite};
  @media screen and (max-width: 1024px) {
    padding: 50px 5%;
  }
  @media screen and (max-width: 450px) {
    padding: 25px 5%;
  }
`

type TextProps = {
  color?: string
}

export const Text = styled(Title)<TextProps>`
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  color: ${({ color, theme }) => color || theme.colors.orange};
  @media screen and (max-width: 768px) {
    line-height: 40px;
  }
  @media screen and (max-width: 450px) {
    font-size: 20px;
    line-height: 24px;
  }
`
