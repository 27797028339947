import { FC } from 'react'

import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import store from 'lib/redux'
import theme from 'lib/theme'
import StyledApp from 'lib/theme/StyledApp'
import Persist from 'views/components/Persist'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Router from './Router'

const App: FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Persist loading={null}>
        <StyledApp />
        <Router />
      </Persist>
    </ThemeProvider>
  </Provider>
)
export default App
