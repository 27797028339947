import React, { FC, useRef } from 'react'
import { Block } from 'views/components/UI/Contents'
import { useDispatch } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { LOGIN } from 'ducks/user/actionTypes'

const SignInSchema = Yup.object().shape({
  password: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
})

const Login: FC = () => {
  const initialValues = useRef({
    email: 'eve.holt@reqres.in',
    password: 'cityslicka',
  })

  const dispatch = useDispatch()

  const handleLogin = (values: any): void => {
    dispatch(LOGIN.trigger(values))
  }

  return (
    <Formik validationSchema={SignInSchema} onSubmit={handleLogin} initialValues={initialValues.current}>
      {({ errors, touched }) => (
        <Form>
          <Block display="flex" flexDirection="column">
            <Block m="1rem">
              <Field name="email" />
              {errors.email && touched.email ? <div>{errors.email}</div> : null}
            </Block>
            <Block m="1rem">
              <Field name="password" type="password" />
              {errors.password && touched.password ? <div>{errors.password}</div> : null}
            </Block>
            <Block m="1rem">
              <button type="submit">Submit</button>
            </Block>
          </Block>
        </Form>
      )}
    </Formik>
  )
}

export default Login
