import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { GoArrowUp } from 'react-icons/go'

import { postPageSelector, LOAD_POST } from 'ducks/pages'
import useMoment from 'hooks/useMoment'
import { TextBox } from 'views/components/UI/Contents'
import { Title } from 'views/components/UI/Typography'
import HtmlRender from 'views/components/HtmlRender'
import {
  Banner,
  Container,
  ContentContainer,
  ContentHeader,
  LabelAuthor,
  LabelDate,
  Labels,
  ScrollUpButton,
} from './styled'

type Params = {
  slug: string
}

const Post: FC = () => {
  const { slug } = useParams<Params>()
  const dispatch = useDispatch()
  const post = useSelector(postPageSelector)
  const { dateFormat } = useMoment()

  useEffect(() => {
    dispatch({ type: LOAD_POST, payload: slug })
  }, [dispatch, slug])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Container>
      {post?.banner?.url && <Banner background={`url(${post?.banner?.url})`} />}
      <ContentContainer>
        <ContentHeader>
          <Title>{post?.title}</Title>
          <Labels>
            <LabelAuthor>Written by {post?.author}</LabelAuthor>
            <LabelDate>{post?.createdAt ? dateFormat(post?.createdAt, 'dddd MMM YY') : ''}</LabelDate>
          </Labels>
        </ContentHeader>
        {post?.content && (
          <TextBox>
            <HtmlRender>{post?.content}</HtmlRender>
          </TextBox>
        )}
        {/* <ScrollUpButton onClick={scrollToTop}>
          <GoArrowUp size={28} />
        </ScrollUpButton> */}
      </ContentContainer>
    </Container>
  )
}

export default Post
