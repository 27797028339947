import { homePageSelector, LOAD_HOME } from 'ducks/pages'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'views/components/Carousel'
import { Feedbacks } from 'views/components/Feedbacks'
import { Goals } from 'views/components/Goals'
import { Tabs } from 'views/components/Tabs'

import { Container, GoalsSection, TabsSection } from './styled'

const Home: FC = () => {
  const dispatch = useDispatch()
  const home = useSelector(homePageSelector)
  const { carousel, feedbacks, goals, tabs } = home || {}

  useEffect(() => {
    dispatch({ type: LOAD_HOME })
  }, [dispatch])

  return (
    <Container>
      <Carousel items={carousel} />
      <TabsSection>
        <Tabs items={tabs?.items} subtitle={tabs?.subtitle} title={tabs?.title} />
      </TabsSection>
      <GoalsSection>
        <Goals items={goals?.items} subtitle={goals?.subtitle} title={goals?.title} />
      </GoalsSection>
      {feedbacks?.items && feedbacks?.items.length ? (
        <Feedbacks items={feedbacks?.items} title={feedbacks?.title} />
      ) : null}
    </Container>
  )
}

export default Home
