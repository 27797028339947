import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  box-shadow: 0px 4px 4px rgba(192, 189, 189, 0.25);
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease-in-out;
  display: flex;
  &:hover {
    box-shadow: 2px 8px 8px rgba(192, 189, 189, 0.9);
  }
  @media screen and (max-width: 450px) {
    flex-direction: column-reverse;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 46px 62px;
  @media screen and (max-width: 768px) {
    padding: 23px 31px;
  }
  @media screen and (max-width: 450px) {
    padding: 16px;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Image = styled.img`
  width: 495px;
  height: 278px;
  @media screen and (max-width: 768px) {
    height: 210px;
    width: 330px;
  }
`

const label = css`
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fontFamily};
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const Label = styled(Link)`
  ${label};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.orange};
  &::after {
    content: ' →';
  }
`

export const LabelDate = styled.p`
  ${label};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.grey600};
`

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.grey600};
  margin: 16px 0 24px;
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 30px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: 450px) {
    font-size: 20px;
    line-height: 30px;
  }
`
