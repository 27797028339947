import { SagaIterator } from '@redux-saga/core'
import { CONTENT } from 'ducks/portfolio'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getPortfolioItems } from 'services/getPortfolioItems'

function* loadPortfolioItemsSaga() {
  yield put(CONTENT.request())
  try {
    const { data } = yield call(getPortfolioItems)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure())
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* portfolioSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadPortfolioItemsSaga)])
}
