/* eslint-disable react/jsx-props-no-spreading */
import { IFeedbackItem } from 'ducks/pages'
import useMediaQuery from 'hooks/useMediaQuery'
import { FC } from 'react'
import Slider, { Settings } from 'react-slick'
import { useTheme } from 'styled-components'
import { Title } from '../UI/Typography'
import {
  Container,
  Content,
  Item,
  ItemAuthor,
  ItemAuthorRole,
  ItemCard,
  ItemCardDialog,
  ItemDescription,
  ItemText,
  ItemTitle,
  Quote,
} from './styled'

type Props = {
  items?: IFeedbackItem[]
  title?: string
}

const sliderSettings: Settings = {
  adaptiveHeight: true,
  autoplay: false,
  dots: true,
  draggable: false,
  swipeToSlide: false,
  speed: 1000,
  useCSS: true,
  arrows: false,
  slidesToShow: 2,
}

const renderItem = (item: IFeedbackItem) => {
  return (
    <Item key={item.id}>
      <ItemCard>
        <ItemCardDialog />
        <ItemTitle>{item.title}</ItemTitle>
        <ItemText>{item.message}</ItemText>
      </ItemCard>
      <ItemDescription>
        <ItemAuthor>{item.author}</ItemAuthor>
        <ItemAuthorRole>{item?.role}</ItemAuthorRole>
      </ItemDescription>
    </Item>
  )
}

export const Feedbacks: FC<Props> = ({ items, title }) => {
  const theme = useTheme()
  const matches = useMediaQuery('(max-width: 768px)')

  return (
    <Container>
      <Quote />
      <Title color={theme.colors.white}>{title}</Title>
      <Content data-testid="feedbacks">
        <Slider {...sliderSettings} slidesToShow={matches ? 1 : 2}>
          {items?.map(renderItem)}
        </Slider>
      </Content>
    </Container>
  )
}
