import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Row } from '../UI/Contents'

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0 46px;
  position: relative;
  @media screen and (max-width: 450px) {
    padding: 15px 0 23px;
  }
`

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 0 15%;
  background-color: ${({ theme }) => theme.colors.blackBackground};
  width: 100%;
  @media screen and (max-width: 1024px) {
    padding: 0 5%;
  }
`

const link = css`
  margin: 0 12px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const LinkExternal = styled.a`
  ${link}
`

export const LinkInternal = styled(Link)`
  ${link}
`

export const Logo = styled.img`
  height: 140px;
  width: 110px;
  align-self: center;
  margin-top: 40px;
  @media screen and (max-width: 450px) {
    margin-top: 20px;
    height: 80px;
    width: 60px;
  }
`

export const Options = styled(Row)`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 46px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  @media screen and (max-width: 450px) {
    padding: 23px 0;
  }
`

export const SocialIcon = styled.img`
  height: 13px;
  width: 13px;
`

export const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #23252e;
  margin: 0 10px;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: brightness(170%);
    transform: translateY(-2px);
  }
`

export const Socials = styled(Row)`
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  @media screen and (max-width: 450px) {
    position: relative;
    margin-top: 21px;
  }
`
