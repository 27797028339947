import { blogPostsSelector, CONTENT } from 'ducks/blog'
import { blogPageSelector, LOAD_BLOG } from 'ducks/pages'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Banner } from 'views/components/Banner'
import { Gallery } from 'views/components/Gallery'
import { Highlight } from 'views/components/Highlight'
import { Section } from 'views/components/UI/Contents'
import { Container, Content } from './styled'

const Blog: FC = () => {
  const dispatch = useDispatch()
  const blog = useSelector(blogPageSelector)
  const posts = useSelector(blogPostsSelector)
  const { banner, highlight } = blog || {}

  useEffect(() => {
    dispatch({ type: LOAD_BLOG })
    dispatch(CONTENT.trigger())
  }, [dispatch])

  return (
    <Container>
      <Banner
        title={banner?.title}
        backgroundColor={banner?.color}
        message={banner?.message}
        imageUrl={banner?.image?.url}
      />
      <Content>
        <Section noVerticalMargin>
          {highlight && (
            <Highlight
              key={highlight?.slug}
              title={highlight?.title}
              description={highlight?.description}
              createdAt={highlight?.createdAt}
              slug={highlight?.slug}
              thumbnail={highlight?.thumbnail?.url}
            />
          )}
        </Section>
        <Section noVerticalMargin>
          <Gallery items={posts} />
        </Section>
      </Content>
    </Container>
  )
}

export default Blog
