import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

export const ContentDescription = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 70px;
  @media screen and (max-width: 768px) {
    padding-left: unset;
  }
`

export const ContentImage = styled.img`
  width: 440px;
  height: 440px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 30px;
  }
  @media screen and (max-width: 450px) {
    height: 280px;
  }
`

export const ContentLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`

const contentLink = css`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.orange};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 60px;
  &::after {
    content: ' →';
  }
  &:hover {
    text-decoration: underline;
  }
`

export const ContentLinkExternal = styled.a`
  ${contentLink}
`

export const ContentLinkInternal = styled(Link)`
  ${contentLink}
`

export const ContentSubtitle = styled.h3`
  color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  margin: 10px 0;
  @media screen and (max-width: 450px) {
    font-size: 20px;
    line-height: 24px;
  }
`

export const ContentText = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  line-height: 32px;
  @media screen and (max-width: 450px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const ContentTitle = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 800;
  font-size: 40px;
  line-height: 54px;
  @media screen and (max-width: 450px) {
    font-size: 28px;
    line-height: 34px;
  }
`

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  margin: 70px 0;
  border-bottom: 1px solid #abaaaa;
  width: 100%;
  @media screen and (max-width: 1024px) {
    padding: 0;
    margin: 40px 0;
  }
  @media screen and (max-width: 768px) {
    overflow-x: scroll;
  }
`

type NavigationButtonProps = {
  active?: boolean
}

export const NavigationButton = styled.button<NavigationButtonProps>`
  border: none;
  border-radius: 50px;
  background-color: ${({ active, theme }) => (active ? theme.colors.orange : 'transparent')};
  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.black)};
  font-family: ${({ theme }) => theme.fontFamily};
  padding: 10px ${({ active }) => (active ? '20px' : '10px')};
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  @media screen and (max-width: 450px) {
    font-size: 16px;
    line-height: 20px;
  }
`
