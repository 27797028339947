/* eslint-disable react/jsx-props-no-spreading */
import { ILink } from '@types'

import { FC } from 'react'
import Slider, { Settings } from 'react-slick'

import NextPNG from 'assets/next.png'
import PlayPNG from 'assets/play.png'
import PreviousPNG from 'assets/previous.png'
import { ICarouselItem } from 'ducks/pages'
import { PageTitle, Text } from 'views/components/UI/Typography'

import {
  Container,
  Content,
  LinkButton,
  LinkButtonIcon,
  LinkExternal,
  LinkInternal,
  LinkLabel,
  LinkSeparator,
  NavigationButton,
  NavigationButtonIcon,
} from './styled'

export type CarouselItem = {
  background?: string
  id: number
  text?: string
  title?: string
  link?: ILink
}

type Props = {
  items?: ICarouselItem[]
}

const sliderSettings: Settings = {
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: false,
  draggable: false,
  pauseOnHover: true,
  swipeToSlide: false,
  speed: 1000,
  useCSS: true,
}

export const Carousel: FC<Props> = ({ items }) => {
  const renderItem = (item: ICarouselItem) => {
    const { link } = item
    const linkContent = (
      <>
        <LinkButton>
          <LinkButtonIcon src={PlayPNG} />
        </LinkButton>
        <LinkSeparator />
        <LinkLabel>{link?.label}</LinkLabel>
      </>
    )

    const renderLink = () => {
      if (!link) {
        return null
      }
      return link?.external ? (
        <LinkExternal key={link.path} href={link.path} target="blank">
          {linkContent}
        </LinkExternal>
      ) : (
        <LinkInternal key={link.path} to={link?.path || ''}>
          {linkContent}
        </LinkInternal>
      )
    }

    return (
      <Content key={item.id} backgroundUrl={item?.background?.url}>
        <PageTitle>{item?.title}</PageTitle>
        <Text>{item?.subtitle}</Text>
        {renderLink()}
      </Content>
    )
  }

  const prevButton = (
    <NavigationButton navigationType="previous">
      <NavigationButtonIcon src={PreviousPNG} />
    </NavigationButton>
  )

  const nextButton = (
    <NavigationButton navigationType="next">
      <NavigationButtonIcon src={NextPNG} />
    </NavigationButton>
  )

  return (
    <Container>
      <Slider {...sliderSettings} nextArrow={nextButton} prevArrow={prevButton}>
        {items?.map(renderItem)}
      </Slider>
    </Container>
  )
}
