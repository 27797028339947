import { all, call } from 'redux-saga/effects'

import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { userSagas } from 'ducks/user/sagas'
import { blogSagas } from 'ducks/blog/sagas'
import { contactSagas } from 'ducks/contact/sagas'
import { staticContentSagas } from 'ducks/staticContent/sagas'
import { pagesSagas } from 'ducks/pages/sagas'
import { SagaIterator } from 'redux-saga'
import { portfolioSagas } from 'ducks/portfolio'

export default function* rootSaga(): SagaIterator {
  try {
    yield all([
      call(routinePromiseWatcherSaga),
      call(blogSagas),
      call(contactSagas),
      call(pagesSagas),
      call(portfolioSagas),
      call(staticContentSagas),
      call(userSagas),
    ])
  } catch (error) {
    // eslint-disable-next-line
    console.error(error)
  }
}
