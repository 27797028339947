import { createGlobalStyle } from 'styled-components'

const StyledApp = createGlobalStyle`
  body {
    width: 100vw;
    margin:0;
    overflow-x: hidden;
    padding:0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    width: 100vw;
    overflow-x: hidden;
    box-sizing: border-box;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`
export default StyledApp
