import { SagaIterator } from '@redux-saga/core'
import { StaticContentResponse } from '@types'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getStaticContent } from 'services/getStaticContent'

import { CONTENT } from '../actionTypes'

function* loadStaticContentSaga() {
  yield put(CONTENT.request())
  try {
    const { data }: StaticContentResponse = yield call(getStaticContent)
    if (data) {
      yield put(CONTENT.success(data))
    }
    yield put(CONTENT.success(data))
  } catch (error) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* staticContentSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, loadStaticContentSaga)])
}
