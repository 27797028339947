import { IPageItem } from 'ducks/pages'
import { FC } from 'react'
import { Text, Title } from '../UI/Typography'
import { Item } from './Item'
import { Container, Grid, Header } from './styled'

type Props = {
  title?: string
  subtitle?: string
  items?: IPageItem[]
}

export const Gallery: FC<Props> = ({ title, subtitle, items }) => {
  const renderItem = (item: IPageItem) => {
    const { description, createdAt, slug, thumbnail } = item
    return (
      <Item
        key={slug}
        title={item.title}
        description={description}
        createdAt={createdAt}
        slug={slug}
        thumbnail={thumbnail?.url}
      />
    )
  }
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <Text>{subtitle}</Text>
      </Header>
      <Grid data-testid="cards">{items?.map(renderItem)}</Grid>
    </Container>
  )
}
