export const article = `
  article {
    title
    text
    image {
      url
    }
  }
`

export const bannerSection = `
  banner {
    title
    message
    color
    image {
      url
    }
  }
`

export const basicPostQuery = `
  title
  description
  slug
  createdAt: created_at
  thumbnail {
    url
  }
`

export const carousel = `
  carousel {
    id
    title
    subtitle
    background {
      url
    }
    link {
      label
      external
      path
    }
  }
`

export const feedbacks = `
  feedbacks {
    title
    items {
      id
      author
      message
      role
      title
    }
  }
`

export const gallery = `
  gallery {
    title
    subtitle
    link_label
    items: pages {
      title
      description
      slug
      createdAt: created_at
      thumbnail {
        url
      }
    }
  }
`

export const goals = `
  goals {
    title
    subtitle
    items {
      id
      title
      description
      icon {
        url
      }
    }
  }
`

export const quote = `
  quote {
    text
    color
    textColor
  }
`

export const tabs = `
  tabs {
    title
    subtitle
    items {
      id
      title
      subtitle
      text
      main_link
      main_link_label
      main_link_external
      link
      link_label
      link_external
      image {
        url
      }
    }
  }
`

export const videoSection = `
  video {
    title
    description
    video_url
  }
`
