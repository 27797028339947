import { FC, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { CONTENT, footerSelector, headerSelector } from 'ducks/staticContent'
import { Footer } from 'views/components/Footer'
import { Header } from 'views/components/Header'
import MainRouter from './Main'

const Router: FC = () => {
  const dispatch = useDispatch()
  const footer = useSelector(footerSelector)
  const header = useSelector(headerSelector)
  useEffect(() => {
    dispatch(CONTENT.trigger())
  }, [dispatch])
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name={header?.page_opengraph?.title} content={header?.page_opengraph?.title} />
        <meta name={header?.page_opengraph?.description} content={header?.page_opengraph?.description} />
        <meta property="og:image" content={header?.page_opengraph?.image.url} />
      </Helmet>
      <Header logo={header?.logo?.url} options={header?.options} />
      <MainRouter />
      <Footer logo={footer?.logo?.url} options={footer?.options} socials={footer?.socials} terms={footer?.terms} />
    </BrowserRouter>
  )
}
export default Router
