import { FC } from 'react'
import { Text, Title } from '../UI/Typography'
import { Container, Description, Image, StyledLink, StyledLinkContainer } from './styled'

type Props = {
  imageUrl?: string
  link?: string
  linkLabel?: string
  text?: string
  title?: string
  side?: 'left' | 'right'
}

export const Project: FC<Props> = ({ imageUrl, link, linkLabel = 'Get started', text, title, side }) => (
  <Container side={side}>
    {imageUrl && <Image data-testid="image" src={imageUrl} />}
    <Description>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <StyledLinkContainer>{link && <StyledLink to={link}>{linkLabel}</StyledLink>}</StyledLinkContainer>
    </Description>
  </Container>
)
