import { SagaIterator } from '@redux-saga/core'
import { all, put, call, takeLatest } from 'redux-saga/effects'
import login from 'services/login'
import { LoginAction } from '../models/actions'
import * as TYPES from '../actionTypes'

function* loginSaga(action: LoginAction) {
  yield put(TYPES.LOGIN.request())
  try {
    const { data: user } = yield call(login, action?.payload?.email, action?.payload?.password)
    if (user.token) {
      yield put(
        TYPES.LOGIN.success({
          email: action?.payload?.email,
          token: user.token,
        })
      )
    } else {
      yield put(TYPES.LOGIN.failure('Error in login'))
    }
  } catch (err) {
    yield put(TYPES.LOGIN.failure(err.message))
  } finally {
    yield put(TYPES.LOGIN.fulfill())
  }
}

export function* userSagas(): SagaIterator {
  yield all([takeLatest(TYPES.LOGIN.TRIGGER, loginSaga)])
}
