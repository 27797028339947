import { SagaIterator } from '@redux-saga/core'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { submitContact } from 'services/submitContact'
import { ServiceResponse } from '@types'
import { ContactAction } from '../models'
import { SUBMIT } from '../actionTypes'

function* submitContactSaga(action: ContactAction) {
  yield put(SUBMIT.request())
  try {
    const { ok }: ServiceResponse = yield call(submitContact, action.payload)
    if (ok) {
      yield put(SUBMIT.success())
    }
  } catch (error: any) {
    yield put(SUBMIT.failure(error.message))
  } finally {
    yield put(SUBMIT.fulfill())
  }
}

export function* contactSagas(): SagaIterator {
  yield all([takeLatest(SUBMIT.TRIGGER, submitContactSaga)])
}
