import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    black: '#18191f',
    blackBackground: '#0B0D17',
    green: '#21ef5b',
    grey600: '#718096',
    grey: '#817E7E',
    greyDark: '#878787',
    greyLight: '#b4bec8',
    offWhite: '#E2EDF1',
    orange: '#F26522',
    orangeBackground: '#F57730',
    red: '#f23a22',
    white: '#fff',
  },
  fontFamily: 'Inter',
}

export default theme
