import { ReactNode, PropsWithChildren, FC } from 'react'
import { useSelector } from 'react-redux'
import { getRehydrateSelector } from 'ducks/persist'
/**
 * Loader component. Shows a loading screen until the persistence rehydration
 * is finished.
 */

interface Props {
  loading?: ReactNode
  children: ReactNode
}

const PersistLoad: FC<Props> = (props: PropsWithChildren<any>) => {
  const rehydrate = useSelector(getRehydrateSelector)
  const { children, loading = null } = props
  return rehydrate ? children : loading
}

export default PersistLoad
