import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { ActionCreator } from '@types'
import { IStaticContent } from '../models'
import * as TYPES from '../actionTypes'

export const initialState: IStaticContent = fromJS({
  header: {
    logo: {
      url: 'https://i.imgur.com/OHrgYtS.png',
    },
    options: [],
  },
})

export const staticContentReducer: Reducer<IStaticContent, ActionCreator> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TYPES.SET:
      return state.merge(payload)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', payload)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.REQUEST:
      return state.set('loading', true)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(payload)
    default:
      return state
  }
}
