import styled, { keyframes } from 'styled-components'
import LogoPNG from 'assets/logo.png'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`

const glowing = keyframes`
  0% { opacity: 0.7; transform: scale(0.9) }
  50% { opacity: 1; transform: scale(1);  }
  100% { opacity: 0.7; transform: scale(0.9) }
`

export const Logo = styled.img`
  width: 100px;
  height: 100px;
  animation: ${glowing} 1.5s ease-in-out infinite forwards;
`

Logo.defaultProps = {
  src: LogoPNG,
}
