import { Store, ActionCreator } from '@types'
import persist from 'ducks/persist/reducer'
import { combineReducers } from 'redux-immutable'
import userReducer from 'ducks/user/reducer'
import { staticContentReducer } from 'ducks/staticContent/reducers'
import { blogReducer } from 'ducks/blog/reducers'
import { contactReducer } from 'ducks/contact/reducers'
import { pagesReducer } from 'ducks/pages/reducers'
import { Reducer } from 'redux'
import { portfolioReducer } from 'ducks/portfolio'

const appReducer = combineReducers({
  blogReducer,
  contactReducer,
  userReducer,
  pagesReducer,
  portfolioReducer,
  staticContentReducer,
  persist,
})

const rootReducer: Reducer<Store, ActionCreator> = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
