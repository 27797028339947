import { FC, useCallback, useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import AboutUs from 'views/screens/AboutUs'
import Blog from 'views/screens/Blog'
import Contact from 'views/screens/Contact'
import Post from 'views/screens/Post'
import Home from 'views/screens/Home'
import Login from 'views/screens/Login'
import Portfolio from 'views/screens/Portfolio'
import PrivacyPolicy from 'views/screens/PrivacyPolicy'
import ContentPage from 'views/screens/ContentPage'
import PortfolioItem from 'views/screens/PortfolioItem'
import { pagesSelector } from 'ducks/pages'
import { useSelector } from 'react-redux'
import { Loader } from 'views/components/Loader'

const MainRouter: FC = () => {
  const history = useHistory()
  const [pathname, setPathname] = useState('/')
  const [displayLoading, setDisplayLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const pageState = useSelector(pagesSelector)
  const loading = pageState.get('loading')

  const shouldLoadPage = useCallback((): boolean => {
    switch (pathname) {
      case '/':
        return !pageState?.get('home')
      case '/contact':
        return !pageState?.get('contact')
      case '/portfolio':
        return !pageState?.get('portfolio')
      case '/blog':
        return !pageState?.get('blog')
      case '/about-us':
        return !pageState?.get('aboutUs')
      case '/privacy-policy':
        return !pageState?.get('privacy')
      default:
        return false
    }
  }, [pathname, pageState])

  useEffect(() => {
    if (loading === false) {
      setFirstLoad(false)
    }
  }, [loading])

  useEffect(() => {
    const load = loading || false
    const shouldLoad = load && shouldLoadPage()
    setDisplayLoading(shouldLoad)
  }, [loading, shouldLoadPage])

  useEffect(() => {
    // Method to go to the top of page when change route
    history.listen(() => {
      window.scrollTo(0, 0)
      setPathname(history.location.pathname)
    })
  }, [history])

  return (
    <>
      <Loader visible={firstLoad || displayLoading} />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/contact" exact>
          <Contact />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/portfolio" exact>
          <Portfolio />
        </Route>
        <Route path="/portfolio/:slug">
          <PortfolioItem />
        </Route>
        <Route path="/blog/post/:slug">
          <Post />
        </Route>
        <Route path="/page/:slug">
          <ContentPage />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
      </Switch>
    </>
  )
}

export default MainRouter
