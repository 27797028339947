import { Link } from 'react-router-dom'
import styled from 'styled-components'

type ContainerProps = {
  side?: 'left' | 'right'
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 60px 0;
  flex-direction: ${({ side }) => (side === 'right' ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
  }
`

export const Description = styled.div`
  padding: 0 40px;
  @media screen and (max-width: 768px) {
    padding-left: unset;
  }
`

export const Image = styled.img`
  height: 500px;
  width: 50%;
  max-width: 500px;
  @media screen and (max-width: 1024px) {
    height: 450px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
    height: 500px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`

export const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.orange};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  &::after {
    content: ' →';
  }
  &:hover {
    text-decoration: underline;
  }
`

export const StyledLinkContainer = styled.div`
  margin-top: 20px;
`
