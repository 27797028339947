import styled from 'styled-components'

export const Container = styled.main`
  padding-top: 72px;
`

export const GallerySection = styled.section`
  padding: 0 15%;
  @media screen and (max-width: 1024px) {
    padding: 0 5% 30px;
  }
`
