import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 25px 0;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const Description = styled.div`
  padding-left: 36px;
  @media screen and (max-width: 768px) {
    padding-left: unset;
  }
`

export const Image = styled.img`
  height: 400px;
  width: 50%;
  max-width: 610px;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
    height: 500px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 450px) {
    height: 250px;
  }
`
