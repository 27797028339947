import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'
import BackgroundPNG from 'assets/banner_background.png'

type ContainerProps = {
  backgroundColor?: string
}

export const Container = styled.div<ContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: 450px) {
    flex-direction: column-reverse;
  }
`

export const Content = styled.div`
  width: 50%;
  padding: 10% 5% 80px 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: url(${BackgroundPNG});
  background-repeat: no-repeat;
  background-position-y: 40%;
  @media screen and (max-width: 1024px) {
    padding: 10% 5% 80px 5%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    padding: 40px 5%;
  }
`

export const ContentImage = styled.div<BackgroundProps>`
  ${background};
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 300px;
  }
  @media screen and (min-width: 1600px) {
    height: 600px;
  }
`
