import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const Header = styled.div`
  width: 55%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

export const Grid = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  width: 100%;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
`
