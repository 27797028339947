import { ServiceResponse } from '@types'
import axios from 'axios'
import { basicPostQuery } from 'services/pages/queries'

export const getPosts = async (): Promise<ServiceResponse> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          posts: pages {
            ${basicPostQuery}
          }
        }      
      `,
    })
    return {
      ok: true,
      data,
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}
