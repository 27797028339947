import { IGoalItem } from 'ducks/pages'
import { FC } from 'react'
import { Text, Title } from '../UI/Typography'
import { Container, Grid, Header, Item, ItemIcon, ItemText, ItemTitle } from './styled'

type Props = {
  items?: IGoalItem[]
  subtitle?: string
  title?: string
}

const renderItem = (item: IGoalItem) => {
  return (
    <Item key={item.id}>
      {item.icon && <ItemIcon src={item.icon?.url} />}
      <ItemTitle>{item.title}</ItemTitle>
      <ItemText>{item.description}</ItemText>
    </Item>
  )
}

export const Goals: FC<Props> = ({ items, subtitle, title }) => {
  return (
    <Container>
      <Header>
        <Title textAlign="center">{title}</Title>
        <Text textAlign="center">{subtitle}</Text>
      </Header>
      <Grid data-testid="goals">{items?.map(renderItem)}</Grid>
    </Container>
  )
}
