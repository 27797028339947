import { FC, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Container, Logo } from './styled'

const loaderRoot = document.getElementById('loader')

type Props = {
  visible?: boolean
}

export const Loader: FC<Props> = ({ visible }) => {
  const [element, setElement] = useState<HTMLDivElement>()

  useEffect(() => {
    const el = document.createElement('div')
    loaderRoot?.appendChild(el)
    setElement(el)
  }, [])

  return element && visible
    ? createPortal(
        <Container>
          <Logo />
        </Container>,
        element || null
      )
    : null
}
