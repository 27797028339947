import styled from 'styled-components'

export const Container = styled.main`
  min-height: 100vh;
  padding-top: 72px;
`

export const Content = styled.div`
  padding: 50px 0;
`
