import { FC } from 'react'
import ReactPlayer from 'react-player'
import { Text, Title } from '../UI/Typography'
import { Container, VideoContainer, VideoWrapper } from './styled'

type Props = {
  description?: string
  title?: string
  videoUrl: string
}

export const Video: FC<Props> = ({ description, title, videoUrl }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Text>{description}</Text>
      <VideoContainer>
        <ReactPlayer url={videoUrl} wrapper={VideoWrapper} controls />
      </VideoContainer>
    </Container>
  )
}
