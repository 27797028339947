import { createRoutine } from 'redux-saga-routines'

export const SET = 'PAGES/SET'
export const CONTENT = createRoutine('PAGES/CONTENT')
export const LOAD_ABOUT_US = 'PAGES/LOAD_ABOUT_US'
export const LOAD_BLOG = 'PAGES/LOAD_BLOG'
export const LOAD_CONTACT = 'PAGES/LOAD_CONTACT'
export const LOAD_CONTENT_PAGE = 'PAGES/LOAD_CONTENT_PAGE'
export const LOAD_HOME = 'PAGES/LOAD_HOME'
export const LOAD_PORTFOLIO = 'PAGES/LOAD_PORTFOLIO'
export const LOAD_PORTFOLIO_ITEM = 'PAGES/LOAD_PORTFOLIO_ITEM'
export const LOAD_POST = 'PAGES/LOAD_POST'
export const LOAD_PRIVACY_POLICY = 'PAGES/LOAD_PRIVACY_POLICY'
