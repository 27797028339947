import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { ActionCreator } from '@types'
import { IContact } from '../models'
import * as TYPES from '../actionTypes'

export const initialState: IContact = fromJS({})

export const contactReducer: Reducer<IContact, ActionCreator> = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.SET:
      return state.merge(payload)
    case TYPES.SUBMIT.FAILURE:
      return state.set('error', payload)
    case TYPES.SUBMIT.FULFILL:
      return state.set('loading', false)
    case TYPES.SUBMIT.REQUEST:
      return state.set('loading', true)
    case TYPES.SUBMIT.SUCCESS:
      return state.set('success', true)
    case TYPES.RESET:
      return initialState
    default:
      return state
  }
}
