import { PageRequestResponse, PageResponse } from '@types'
import axios from 'axios'
import {
  article,
  bannerSection,
  basicPostQuery,
  carousel,
  feedbacks,
  gallery,
  goals,
  quote,
  tabs,
  videoSection,
} from './queries'

export const getAboutUs = async (): Promise<PageResponse> => {
  try {
    const {
      data: { data },
    }: PageRequestResponse = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          aboutUs {
            ${bannerSection}
            ${videoSection}
            ${quote}
            ${article}
          }
        }      
      `,
    })
    return {
      ok: true,
      data: {
        aboutUs: data.aboutUs,
      },
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const getBlog = async (): Promise<PageResponse> => {
  try {
    const {
      data: { data },
    }: PageRequestResponse = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          blog {
            ${bannerSection}
            highlight {
              ${basicPostQuery}
            }
          }
        }      
      `,
    })
    return {
      ok: true,
      data: {
        blog: data.blog,
      },
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const getContact = async (): Promise<PageResponse> => {
  try {
    const {
      data: { data },
    }: PageRequestResponse = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          contact {
            title
            subtitle
            message
            phone
            email
            form {
              title
              name
              email
              company
              role
              message
              button
            }
          }
        }
      `,
    })
    return {
      ok: true,
      data: {
        contact: data.contact,
      },
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const getContent = async (slug: string): Promise<PageResponse> => {
  try {
    const {
      data: { data },
    }: PageRequestResponse = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          contentPages(where:{
            slug: "${slug}"
          }) {
            title
            content
            videoUrl: video_url
            ${article}
            ${quote}
            banner {
              url
            }
          }
        }
      `,
    })
    if (data.contentPages && data.contentPages[0]) {
      return {
        ok: true,
        data: {
          content: data.contentPages[0],
        },
      }
    }
    return {
      ok: false,
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const getPost = async (slug: string): Promise<PageResponse> => {
  try {
    const {
      data: { data },
    }: PageRequestResponse = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          pages(where:{
            slug: "${slug}"
          }) {
            author
            title
            content
            createdAt: created_at
            banner {
              url
            }
          }
        }
      `,
    })
    if (data.pages && data.pages[0]) {
      return {
        ok: true,
        data: {
          post: data.pages[0],
        },
      }
    }
    return {
      ok: false,
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const getPortfolioItem = async (slug: string): Promise<PageResponse> => {
  try {
    const {
      data: { data },
    }: PageRequestResponse = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          portfolioItems(where:{
            slug: "${slug}"
          }) {
            ${bannerSection}
            contentPages: content_pages {
              title
              subtitle
              description
              slug
              thumbnail {
                url
              }
            }
          }
        }
      `,
    })
    if (data.portfolioItems && data.portfolioItems[0]) {
      return {
        ok: true,
        data: {
          portfolioItem: data.portfolioItems[0],
        },
      }
    }
    return {
      ok: false,
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const getHome = async (): Promise<PageResponse> => {
  try {
    const {
      data: { data },
    }: PageRequestResponse = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          home {
            ${carousel}
            ${feedbacks}
            ${goals}
            ${tabs}
          }
        }
      `,
    })
    return {
      ok: true,
      data: {
        home: data.home,
      },
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const getPortfolio = async (): Promise<PageResponse> => {
  try {
    const {
      data: { data },
    }: PageRequestResponse = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          portfolio {
            ${bannerSection}
            ${gallery}
          }
        }
      `,
    })
    return {
      ok: true,
      data: {
        portfolio: data.portfolio,
      },
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export const getPrivacyPolicy = async (): Promise<PageResponse> => {
  try {
    const {
      data: { data },
    }: PageRequestResponse = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
        query {
          privacyPolicy {
            content
          }
        }
      `,
    })
    return {
      ok: true,
      data: {
        privacy: data.privacyPolicy,
      },
    }
  } catch (error: any) {
    throw new Error(error.message)
  }
}
