import styled, { css } from 'styled-components'
import { background, BackgroundProps } from 'styled-system'
import { Row } from 'views/components/UI/Contents'
import {} from 'module'

export const Container = styled.main`
  padding: 70px 0;
  min-height: 100vh;
`

export const Banner = styled.div<BackgroundProps>`
  ${background};
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: 440px;
`

export const ContentContainer = styled.div`
  padding: 0 15%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    padding: 0 5%;
  }
`

export const ContentHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
  padding-bottom: 32px;
  margin: 32px 0;
`

const label = css`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  line-height: 22px;
  @media screen and (max-width: 450px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const LabelAuthor = styled.p`
  ${label};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`

export const LabelDate = styled.p`
  ${label};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.grey600};
`

export const Labels = styled(Row)`
  margin-top: 16px;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 450px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`

export const ScrollUpButton = styled.button`
  background: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
`
