import { SagaIterator } from '@redux-saga/core'
import { ServiceResponse } from '@types'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getPosts } from 'services/getPosts'
import { CONTENT } from '../actionTypes'

function* getPostsSaga() {
  yield put(CONTENT.request())
  try {
    const { data }: ServiceResponse = yield call(getPosts)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* blogSagas(): SagaIterator {
  yield all([takeLatest(CONTENT.TRIGGER, getPostsSaga)])
}
