import { SagaIterator } from '@redux-saga/core'
import { PageResponse } from '@types'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  getAboutUs,
  getBlog,
  getContact,
  getContent,
  getHome,
  getPortfolio,
  getPortfolioItem,
  getPost,
  getPrivacyPolicy,
} from 'services/pages'
import { IActionPageContent } from '../models'
import {
  CONTENT,
  LOAD_ABOUT_US,
  LOAD_BLOG,
  LOAD_CONTACT,
  LOAD_CONTENT_PAGE,
  LOAD_HOME,
  LOAD_PORTFOLIO,
  LOAD_PORTFOLIO_ITEM,
  LOAD_POST,
  LOAD_PRIVACY_POLICY,
} from '../actionTypes'

function* loadAboutUsSaga() {
  yield put(CONTENT.request())
  try {
    const { data }: PageResponse = yield call(getAboutUs)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadBlogSaga() {
  yield put(CONTENT.request())
  try {
    const { data }: PageResponse = yield call(getBlog)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadContactSaga() {
  yield put(CONTENT.request())
  try {
    const { data }: PageResponse = yield call(getContact)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadContentSaga(action: IActionPageContent) {
  yield put(CONTENT.request())
  try {
    const { data }: PageResponse = yield call(getContent, action.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadPostSaga(action: IActionPageContent) {
  yield put(CONTENT.request())
  try {
    const { data }: PageResponse = yield call(getPost, action.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadPortfolioItemSaga(action: IActionPageContent) {
  yield put(CONTENT.request())
  try {
    const { data }: PageResponse = yield call(getPortfolioItem, action.payload)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadHomeSaga() {
  yield put(CONTENT.request())
  try {
    const { data }: PageResponse = yield call(getHome)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadPortfolioSaga() {
  yield put(CONTENT.request())
  try {
    const { data }: PageResponse = yield call(getPortfolio)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

function* loadPrivacyPolicySaga() {
  yield put(CONTENT.request())
  try {
    const { data }: PageResponse = yield call(getPrivacyPolicy)
    if (data) {
      yield put(CONTENT.success(data))
    }
  } catch (error: any) {
    yield put(CONTENT.failure(error.message))
  } finally {
    yield put(CONTENT.fulfill())
  }
}

export function* pagesSagas(): SagaIterator {
  yield all([
    takeLatest(LOAD_ABOUT_US, loadAboutUsSaga),
    takeLatest(LOAD_BLOG, loadBlogSaga),
    takeLatest(LOAD_CONTACT, loadContactSaga),
    takeLatest(LOAD_CONTENT_PAGE, loadContentSaga),
    takeLatest(LOAD_POST, loadPostSaga),
    takeLatest(LOAD_HOME, loadHomeSaga),
    takeLatest(LOAD_PORTFOLIO, loadPortfolioSaga),
    takeLatest(LOAD_PORTFOLIO_ITEM, loadPortfolioItemSaga),
    takeLatest(LOAD_PRIVACY_POLICY, loadPrivacyPolicySaga),
  ])
}
