import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { ActionCreator } from '@types'
import { IBlog } from '../models'
import * as TYPES from '../actionTypes'

export const initialState: IBlog = fromJS({
  uuid: '',
})

export const blogReducer: Reducer<IBlog, ActionCreator> = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.SET:
      return state.merge(payload)
    case TYPES.CONTENT.FAILURE:
      return state.set('error', payload)
    case TYPES.CONTENT.FULFILL:
      return state.set('loading', false)
    case TYPES.CONTENT.REQUEST:
      return state.set('loading', true)
    case TYPES.CONTENT.SUCCESS:
      return state.merge(payload)
    default:
      return state
  }
}
