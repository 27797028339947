import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15%;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  z-index: 10;
  @media screen and (max-width: 1024px) {
    padding: 15px 5%;
  }
`

export const link = css`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0px 16px;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const ExternalLink = styled.a`
  ${link}
`

export const InternalLink = styled(Link)`
  ${link}
`

export const Logo = styled.img`
  width: 125px;
  height: 38px;
`

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
