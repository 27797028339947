import createImmutableSelector from 'lib/immutable/selector'
import { IPages } from '../models'

// TODO: import Store type and add reducer type in Store interface
const getPages = (state: any): IPages => state.get('pagesReducer')

export const pagesSelector = createImmutableSelector(getPages, (pages) => pages)

export const aboutUsPageSelector = createImmutableSelector(getPages, (pages) => pages.get('aboutUs'))
export const blogPageSelector = createImmutableSelector(getPages, (pages) => pages.get('blog'))
export const contactPageSelector = createImmutableSelector(getPages, (pages) => pages.get('contact'))
export const contentPageSelector = createImmutableSelector(getPages, (pages) => pages.get('content'))
export const homePageSelector = createImmutableSelector(getPages, (pages) => pages.get('home'))
export const portfolioPageSelector = createImmutableSelector(getPages, (pages) => pages.get('portfolio'))
export const portfolioItemPageSelector = createImmutableSelector(getPages, (pages) => pages.get('portfolioItem'))
export const postPageSelector = createImmutableSelector(getPages, (pages) => pages.get('post'))
export const privacyPageSelector = createImmutableSelector(getPages, (pages) => pages.get('privacy'))
