import { ServiceResponse } from '@types'
import axios from 'axios'
import { ContactValues } from 'ducks/contact'

export const submitContact = async (body: ContactValues): Promise<ServiceResponse> => {
  try {
    await axios.post(`${process.env.REACT_APP_MAIN_API}/contact-emails/submit`, body)
    return { ok: true }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
