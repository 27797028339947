import { useCallback } from 'react'
import moment from 'moment'

type UseMoment = {
  dateFormat: (dateString: string, format: string) => string
  today: string
}

const useMoment = (): UseMoment => {
  const today = moment().utc(true).toISOString()

  const dateFormat = useCallback((dateString: string, format: string) => {
    return moment(dateString).format(format)
  }, [])

  return { dateFormat, today }
}

export default useMoment
