import { LOAD_PRIVACY_POLICY, privacyPageSelector } from 'ducks/pages'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HtmlRender from 'views/components/HtmlRender'
import { Section, TextBox } from 'views/components/UI/Contents'
import { Container } from './styled'

const PrivacyPolicy: FC = () => {
  const dispatch = useDispatch()
  const privacy = useSelector(privacyPageSelector)

  useEffect(() => {
    dispatch({ type: LOAD_PRIVACY_POLICY })
  }, [dispatch])

  return (
    <Container>
      <Section>
        {privacy?.content && (
          <TextBox>
            <HtmlRender>{privacy?.content}</HtmlRender>
          </TextBox>
        )}
      </Section>
    </Container>
  )
}

export default PrivacyPolicy
