import useMoment from 'hooks/useMoment'
import { FC } from 'react'

import { Container, Content, Footer, Image, Label, LabelDate, Text, Title } from './styled'

type Props = {
  createdAt?: string
  description?: string
  slug: string
  title?: string
  thumbnail?: string
}

export const Highlight: FC<Props> = ({ createdAt, description, slug, title, thumbnail }) => {
  const { dateFormat } = useMoment()
  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <Text>{description}</Text>
        <Footer>
          <Label to={`/blog/post/${slug}`}>Read more</Label>
          <LabelDate>{createdAt ? dateFormat(createdAt, 'MMM Do YY') : ''}</LabelDate>
        </Footer>
      </Content>
      {thumbnail && <Image src={thumbnail} />}
    </Container>
  )
}
