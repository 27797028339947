import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const link = css`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
  margin: 8px 0;
  &:hover {
    text-decoration: underline;
  }
`

export const ExternalLink = styled.a`
  ${link}
`

export const InternalLink = styled(Link)`
  ${link}
`

type MenuButtonProps = {
  close?: boolean
}

export const MenuButton = styled.div<MenuButtonProps>`
  position: absolute;
  top: ${({ close }) => close && '22px'};
  right: 15px;
`

type MenuContainerProps = {
  open?: boolean
}

export const MenuContainer = styled.div<MenuContainerProps>`
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 48px 16px 16px;
  width: 50%;
  align-items: center;
  clip-path: circle(${({ open }) => (open ? '200% at 90% -10%' : '0 at 90% -10%')});
  -webkit-clip-path: circle(${({ open }) => (open ? '200% at 90% -10%' : '0 at 90% -10%')});
  transition: all 0.5s ease-in-out;
`

export const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
`
