import { LOAD_PORTFOLIO, portfolioPageSelector } from 'ducks/pages'
import { CONTENT, IPortfolioItem, portfolioItemsSelector } from 'ducks/portfolio'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Banner } from 'views/components/Banner'
import { Project } from 'views/components/Project'
import { Container, GallerySection } from './styled'

const Portfolio: FC = () => {
  const dispatch = useDispatch()
  const portfolio = useSelector(portfolioPageSelector)
  const portfolioItems = useSelector(portfolioItemsSelector)
  const { banner, gallery } = portfolio || {}

  useEffect(() => {
    dispatch({ type: LOAD_PORTFOLIO })
    dispatch(CONTENT.trigger())
  }, [dispatch])

  const renderGalleryItem = (item: IPortfolioItem, index: number) => {
    return (
      <Project
        imageUrl={item.image?.url}
        key={item.slug}
        link={`/portfolio/${item.slug}`}
        linkLabel={gallery?.link_label}
        text={item.description}
        title={item.title}
        side={index % 2 === 0 ? 'left' : 'right'}
      />
    )
  }

  return (
    <Container>
      <Banner title={banner?.title} message={banner?.message} imageUrl={banner?.image?.url} />
      <GallerySection>{portfolioItems?.map(renderGalleryItem)}</GallerySection>
    </Container>
  )
}

export default Portfolio
