import { ServiceResponse } from '@types'
import axios from 'axios'

export const getPortfolioItems = async (): Promise<ServiceResponse> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, {
      query: `
      query {
        portfolioItems {
          title
          description
          slug
          contentPages: content_pages {
            title
            subtitle
            description
            slug
            thumbnail {
              url
            }
          }
          image {
            url
          }
        }
      }
      `,
    })
    return {
      ok: true,
      data: {
        items: data.portfolioItems,
      },
    }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
