import styled, { css } from 'styled-components'
import LoadingSVG from 'assets/loading.svg'

export const Container = styled.main`
  display: flex;
  flex-direction: row;
  height: 100vh;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    height: unset;
  }
`

export const Content = styled.section`
  width: 50%;
  background-color: ${({ theme }) => theme.colors.offWhite};
  height: 100%;
  padding: 120px 50px 100px 15%;
  @media screen and (max-width: 1024px) {
    padding: 120px 50px 100px 5%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    padding: 80px 5% 30px;
  }
`

export const Description = styled.div`
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
`

export const Form = styled.form`
  padding: 120px 15% 100px 115px;
  width: 50%;
  @media screen and (max-width: 1024px) {
    padding: 150px 5% 100px 50px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    padding: 30px 5%;
  }
`

const button = css`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 50px;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-top: 40px;
  border: none;
  text-align: center;
`

export const FormButton = styled.button`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.orange};
  ${button};
`

type FormButtonFeedbackProps = {
  success?: boolean
}

export const FormButtonFeedback = styled.div<FormButtonFeedbackProps>`
  ${button};
  background-color: ${({ success, theme }) => (success ? theme.colors.green : theme.colors.orange)};
  cursor: ${({ success }) => (success ? 'pointer' : 'default')};
  font-size: 14px;
`

export const FormButtonLoadingSVG = styled.img`
  height: 100%;
  width: 20px;
`

FormButtonLoadingSVG.defaultProps = {
  alt: 'Loading Animation',
  src: LoadingSVG,
}

export const FormError = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  line-height: 16px;
  margin: 40px 0 0;
`

export const FormTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 32px;
`
