import { aboutUsPageSelector, LOAD_ABOUT_US } from 'ducks/pages'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Article } from 'views/components/Article'
import { Banner } from 'views/components/Banner'
import { Quote } from 'views/components/Quote'
import { Section } from 'views/components/UI/Contents'
import { Video } from 'views/components/Video'
import { Container } from './styled'

const AboutUs: FC = () => {
  const dispatch = useDispatch()
  const aboutUs = useSelector(aboutUsPageSelector)
  const { article, banner, quote, video } = aboutUs || {}

  useEffect(() => {
    dispatch({ type: LOAD_ABOUT_US })
  }, [dispatch])

  return (
    <Container>
      <Banner
        backgroundColor={banner?.color}
        imageUrl={banner?.image?.url}
        title={banner?.title}
        message={banner?.message}
      />
      <Section>
        {video?.video_url && (
          <Video title={video?.title} description={video?.description} videoUrl={video?.video_url} />
        )}
      </Section>
      {quote?.text && <Quote backgroundColor={quote?.color}>{quote?.text}</Quote>}
      <Section>
        <Article imageUrl={article?.image.url} text={article?.text} title={article?.title} />
      </Section>
    </Container>
  )
}

export default AboutUs
