import { ILink, ISocialLink } from '@types'
import { FC } from 'react'
import { Bottom, Container, LinkExternal, LinkInternal, Logo, Options, SocialIcon, SocialLink, Socials } from './styled'

type Props = {
  logo?: string
  options?: ILink[]
  socials?: ISocialLink[]
  terms?: ILink
}

const renderOption = (option: ILink) => {
  const { external, label, path } = option
  return external ? (
    <LinkExternal key={path} href={path} target="blank">
      {label}
    </LinkExternal>
  ) : (
    <LinkInternal key={path} to={path}>
      {label}
    </LinkInternal>
  )
}

const renderSocial = (social: ISocialLink) => {
  const { icon, name, url } = social || {}
  return (
    <SocialLink key={`${name}: ${url}`} href={url} target="blank">
      <SocialIcon src={icon.url} />
    </SocialLink>
  )
}

export const Footer: FC<Props> = ({ logo, options, socials, terms }) => {
  return (
    <Container>
      {logo && <Logo src={logo} />}
      <Options data-testid="navigationLinks">{options?.map(renderOption)}</Options>
      <Bottom>
        {terms && renderOption(terms)}
        <Socials data-testid="socialLinks">{socials?.map(renderSocial)}</Socials>
      </Bottom>
    </Container>
  )
}
