import { IPageItem, LOAD_PORTFOLIO_ITEM, portfolioItemPageSelector } from 'ducks/pages'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Banner } from 'views/components/Banner'
import { Project } from 'views/components/Project'
import { Section } from 'views/components/UI/Contents'
import { Container } from './styled'

type Params = {
  slug: string
}

const PortfolioItem: FC = () => {
  const { slug } = useParams<Params>()
  const dispatch = useDispatch()
  const portfolio = useSelector(portfolioItemPageSelector)
  const { banner, contentPages } = portfolio || {}

  useEffect(() => {
    dispatch({ type: LOAD_PORTFOLIO_ITEM, payload: slug })
  }, [dispatch, slug])

  const renderPageItem = (item: IPageItem) => {
    return (
      <Project
        imageUrl={item.thumbnail?.url}
        key={item.slug}
        link={`/page/${item.slug}`}
        linkLabel="Get started"
        text={item.description}
        title={item.title}
        side="left"
      />
    )
  }

  return (
    <Container>
      <Banner
        title={banner?.title}
        message={banner?.message}
        backgroundColor={banner?.color}
        imageUrl={banner?.image?.url}
      />
      <Section>{(contentPages as IPageItem[])?.map(renderPageItem)}</Section>
    </Container>
  )
}

export default PortfolioItem
