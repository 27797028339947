// React
import { ILink } from '@types'
import { FC, useState } from 'react'
import { FiX, FiMenu } from 'react-icons/fi'
import { useTheme } from 'styled-components'
import { ExternalLink, InternalLink, MenuButton, MenuContainer, MenuOptions } from './styled'

type Props = {
  options?: ILink[]
}

const Menu: FC<Props> = ({ options }) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  const closeMenu = () => {
    setOpen(false)
  }

  const toggleMenu = () => {
    setOpen((prevState) => !prevState)
  }

  const renderOption = (option: ILink) => {
    return option?.external ? (
      <ExternalLink key={option.path} href={option.path} target="blank">
        {option.label}
      </ExternalLink>
    ) : (
      <InternalLink key={option.path} to={option.path} onClick={closeMenu}>
        {option.label}
      </InternalLink>
    )
  }

  return (
    <>
      <MenuButton onClick={toggleMenu}>
        <FiMenu color={theme.colors.black} size={24} />
      </MenuButton>
      <MenuContainer open={open}>
        <MenuButton close onClick={toggleMenu}>
          <FiX color={theme.colors.black} size={24} />
        </MenuButton>
        <MenuOptions data-testid="options">{options?.map(renderOption)}</MenuOptions>
      </MenuContainer>
    </>
  )
}

export default Menu
