import styled from 'styled-components'
import { Section } from 'views/components/UI/Contents'

export const Container = styled.main`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const TabsSection = styled(Section)`
  padding: 85px 15%;
  @media screen and (max-width: 1024px) {
    padding: 40px 5%;
  }
`

export const GoalsSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.offWhite};
`
