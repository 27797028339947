import styled from 'styled-components'

type InputProps = {
  empty?: boolean
  error?: string
  focus?: boolean
}

export const Container = styled.div`
  margin: 16px 0;
`

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 12px;
  line-height: 16px;
  margin: 4px 2px 0;
`

export const InputContainer = styled.div<InputProps>`
  border: ${({ focus }) => (focus ? '2px' : '1px')} solid
    ${({ error, focus, theme }) => {
      if (error) {
        return theme.colors.red
      }
      if (focus) {
        return theme.colors.black
      }
      return theme.colors.greyLight
    }};
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

export const StyledInput = styled.input<InputProps>`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  padding: 14px;
  background-color: transparent;
  border: none;
  z-index: 1;
  &::placeholder {
    color: transparent;
  }
  &:focus {
    outline: none;
  }
`

export const StyledLabel = styled.label<InputProps>`
  color: ${({ error, focus, theme }) => {
    if (error) {
      return theme.colors.red
    }
    if (focus) {
      return theme.colors.black
    }
    return theme.colors.greyDark
  }};
  font-family: ${({ theme }) => theme.fontFamily};
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  left: 0;
  font-size: 16px;
  line-height: 19px;
  padding: 2px 4px;
  z-index: ${({ focus }) => (focus ? 1 : 0)};
  transform: ${({ empty, focus }) => ((focus && empty) || !empty ? 'translate(9px, -23px)' : 'translate(9px, 0)')};
  transition: all 0.1s ease-in-out;
`
